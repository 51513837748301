import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Página não encontrada" />    
    <Section>
      <div class="container">
        <h2>Erro 404 <small> - Página não encontrada</small></h2>
        <p>Verifique se o endereço que você digitou está correto, se o erro persistir favor entrar em contato conosco.</p>

        <button class="btn btn-danger">Informar problema</button>
        <br/>
        <br/>
      </div>
    </Section>
  </Layout>
)

export default NotFoundPage
